// Cookie
import { deleteAllCookies } from "utils/functions/cookies";

export const apiHelper = async (resFn) => {
    let _result;
    try {
        const res = await resFn();
        if (res.data) {
            if(typeof res.data.result === 'string' || res.data.result instanceof String)
                _result = {
                    result: res.data.result,
                    statusCode: res.data.statusCode
                };
            else
                _result = {
                    ...res.data.result,
                    statusCode: res.data.statusCode
                };
        }
        else {
            _result = {
                statusCode: res.status,
                errors: [res.statusText]
            };
        }
    }
    catch(err) {
        if (err.response) {
            if (err.response.data) {
                if (err.response.data.error) {
                    _result = {
                        statusCode: err.response.status,
                        errors: [err.response.data.error]
                    }
                }
                else if (err.response.data.errors) {
                    let errors = err.response.data.errors.map(hata => {
                        return `${hata.message}${(hata.errorId !== undefined && hata.errorId !== null) ? `${String(hata.message).slice(-1) !== '.' ? "." : ""} Hata numarası: ${hata.errorId}` : ""}`;
                    });
                    _result = {
                        statusCode: err.response.status,
                        errors: errors
                    }
                }
                else {
                    let errors = err.response.data.map((hata) => {
                        return hata.errorMessage;
                    });
                    _result = {
                        statusCode: err.response.status,
                        errors: errors
                    }
                }
            } else {
                if (err.message) {
                    _result = {
                        statusCode: err.response.status,
                        errors: [err.message]
                    };
                } else {
                    _result = {
                        statusCode: err.response.status,
                        errors: ["Bilinmeyen hata"]
                    };
                }
            }
        } else {
            _result = {
                statusCode: 0,
                errors: ["Bilinmeyen hata"]
            }
        }
    }
    if (_result.statusCode === 401)
        deleteAllCookies();

    return _result;
}