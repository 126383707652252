// Immer
import produce from "immer";
// Helper
import { apiHelper } from "utils/helpers";
// Service
import api from "services/api";

export const genericSlice = (set, get) => ({
    genericResult: new Object(),
    loading: false,
    collapsedBoxes: {
        'Duyurular': false,
        // 'Haberler': false,
        'Kurumsal İletişim': false,
        'Kolin İnşaat EYS Dokümanları': false,
        'Eğitim Dokümanları': false,
        'İnteraktif Uygulamalar': false,
        'Yaklaşan Doğum Günleri': false,
        'Yonetim Talimat ve Duyurular': false,
        // "Yayınlar": false
        'Koloğlu Holding EYS Dokümanları':false,
    },
    setCollapsedBoxes: (keys, value) => {
        set(produce((state) => {
            state.collapsedBoxes[keys] = value;
        }))
    },
    // ChangeLoading
    setLoading: (bool) => {
        set(produce((state) => {
            state.loading = bool;
        }));
    },
    // GenericResult içerisindeki veriyi temizler
    clearGenericResult: () => {
        set(produce((state) => {
            state.loading = false;
            state.genericResult = new Object();
        }));
    },
    // Post
    create: async(url, data) => {
        // Bir önceki state'i temizler
        set(produce((state) => {
            state.loading = true;
            state.genericResult = new Object();
        }));
        await apiHelper(async () => await api().post(url, data)).then(value => {
            set(produce((state) => {
                state.genericResult = value;
                state.loading = false;
            }));
        });
    },
    // Put
    update: async(url, data) => {
        // Bir önceki state'i temizler
        set(produce((state) => {
            state.loading = true;
            state.genericResult = new Object();
        }));
        await apiHelper(async () => await api().put(url, data)).then(value => {
            set(produce((state) => {
                state.genericResult = value;
                state.loading = false;
            }));
        });
    },
    // Delete
    remove: async(url) => {
        // Bir önceki state'i temizler
        set(produce((state) => {
            state.loading = true;
            state.genericResult = new Object();
        }));
        await apiHelper(async () => await api().delete(`${url}`)).then(value => {
            set(produce((state) => {
                state.genericResult = value;
                state.loading = false;
            }));
        });
    },
});