// React
import React from 'react';
// Form Validation
import * as yup from 'yup';
import { useFormik } from 'formik';
// Context
import { Context } from 'utils/Context';

const DuyuruValidation = (onSubmitFunction) => {
    const context = React.useContext(Context);
    const validationSchema = yup.object().shape({
        icerik: yup.string().min(10).required(async () => await context.newMessage("error", "Hata", "Başlık en az 10 karakter olmalıdır")),
        icerikDetay: yup.string().min(10).required(async () => await context.newMessage("error", "Hata", "Açıklama en az 10 karakter olmalıdır")),
        icerikLink: yup.string().min(5).nullable(async () => await context.newMessage("error", "Hata", "Link en az 5 karakter içermelidir"))
    });
    return useFormik({
        initialValues: {
            id: "",
            icerik: "",
            icerikDetay: "",
            icerikLink: "",
            tip: "D",
            tarih: null
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: () => {
            onSubmitFunction();
        }
    });
}
export default DuyuruValidation;