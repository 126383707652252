// React
import React, { useState, useEffect, useRef } from "react";
// PrimeReact
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { ContextMenu } from 'primereact/contextmenu';
import { Dialog } from 'components/ui/Dialog';
import { Divider } from 'primereact/divider';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Tree } from 'primereact/tree';
import { ProgressSpinner } from 'primereact/progressspinner';
// Components
import { ShadowedCard } from "components/common/ShadowedCard";
// DocViewer
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
// Hooks
import { useFetch } from "hooks/useFetch";
import { useToggle } from "hooks/useToggle";
import { useStore } from "store";
// Utils
import { downloadFile } from 'utils/functions/download';
// Config
import { API_ADDRESS, FileExtension } from 'Config';
// Services
import api from "services/api";

const YonetimTalimatDuyurular = ({ dosyaAkis }) => {
    // Modal
    const { open: dialogVisible, toggle: dialogToggle } = useToggle();
    const { open: documentVisible, toggle: documentToggle } = useToggle();
    const { open: ozelliklerVisible, toggle: ozelliklerToggle } = useToggle();
    // Variables
    const cm = useRef(null);
    const { collapsedBoxes } = useStore();
    const [breadcrumb, setBreadcrumb] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [document, setDocument] = useState(null);
    // Api
    const [folders, getFolders] = useFetch();
    const [files, getFilesDirectory] = useFetch();

    const getFiles = React.useCallback(async (path = null) => {
        await getFilesDirectory(path === null ? `getFilesAndDirectoriesByPath?path=Dosya-YonetimDuyuru` : `getFilesAndDirectoriesByPath?path=Dosya-YonetimDuyuru/${path}`);

        if (path)
            setBreadcrumb([
                {
                    label: <i className='pi pi-home' />,
                    command: async () => await getFiles()
                },
                ...path.split("\\").map((p) => ({
                    label: p,
                    path: path,
                    command: async () => await getFiles(path.split(p)[0] + p)
                }))
            ]);
        else
            setBreadcrumb([
                {
                    label: <i className='pi pi-home' />,
                    command: async () => await getFiles()
                }
            ]);
    });
    // On Page Render
    const onPageRender = React.useCallback(async () => {
        await getFolders(`getAllDirectories?path=Dosya-YonetimDuyuru`);
        await getFiles();
    });
    useEffect(() => { dialogVisible && onPageRender() }, [dialogVisible]);
    // Functions
    const sortFolders = (items, keyV = "", path = "") => Object.entries(items)?.map(([key, value]) => ({
        ...value,
        key: (`${keyV}-${key}`).charAt(0) === "-" ? (`${keyV}-${key}`).slice(1) : `${keyV}-${key}`,
        path: (`${path}/${key}`).charAt(0) === "/" ? (`${path}/${key}`).slice(1) : `${path}/${key}`,
        children: (value?.children !== undefined && Object.keys(value?.children)?.length !== 0) ? sortFolders(value.children, `${keyV}-${key}`, `${path}/${key}`) : null,
        icon: "pi pi-fw pi-folder"
    }));
    const getDocument = React.useCallback(async (file = null) => {
        if (file) {
            let mimeType = String(FileExtension[file.uzanti.replace(".", "")]);
            if(mimeType.includes("pdf") || mimeType.includes("image")){
                await api().get(`downloadFile?path=${String(file.tamAdi)}&mimeType=${mimeType}`, { responseType: 'blob' }).then((response) => {
                    setDocument([{
                        uri: URL.createObjectURL(response?.data),
                        fileName: file.adi,
                        tamAdi: file.tamAdi,
                        uzanti: file.uzanti,
                        adi: file.adi
                    }]);
                })
            }
            else{
                setDocument([{
                    uri: API_ADDRESS + String(file.tamAdi).replace("/mnt/D","d"),
                    fileName: file.adi,
                    tamAdi: file.tamAdi,
                    uzanti: file.uzanti,
                    adi: file.adi
                }]);
            }
        }
        else {
            let mimeType = String(FileExtension[selectedFile.uzanti.replace(".", "")]);
            if(mimeType.includes("pdf") || mimeType.includes("image"))
            {
                await api().get(`downloadFile?path=${String(selectedFile.tamAdi)}&mimeType=${mimeType}`, { responseType: 'blob' }).then((response) => {
                    setDocument([{
                        uri: URL.createObjectURL(response?.data),
                        fileName: selectedFile.adi,
                        tamAdi: selectedFile.tamAdi,
                        uzanti: selectedFile.uzanti,
                        adi: selectedFile.adi
                    }]);
                })
            }
            else{
                setDocument([{
                    uri: API_ADDRESS + String(selectedFile.tamAdi).replace("/mnt/D","d"),
                    fileName: selectedFile.adi,
                    tamAdi: selectedFile.tamAdi,
                    uzanti: selectedFile.uzanti,
                    adi: selectedFile.adi
                }]);
            }
        }
        !documentVisible && documentToggle();
    });
    // Context Items
    const contextMenuItems = [
        {
            label: 'Görüntüle',
            icon: 'pi pi-fw pi-eye',
            items: null,
            command: async () => await getDocument()
        },
        {
            label: 'İndir',
            icon: 'pi pi-fw pi-download',
            items: null,
            command: async () => await downloadFile(String(selectedFile.tamAdi), selectedFile.uzanti, selectedFile.adi)
        },
        {
            label: 'Özellikler',
            icon: 'pi pi-fw pi-info',
            items: null,
            command: () => !ozelliklerVisible && ozelliklerToggle()
        }
    ];

    return (
        <>
            <ShadowedCard keys="Yönetim Talimat ve Duyurular" title={<div id="textbox">
                {collapsedBoxes["Yönetim Talimat ve Duyurular"] ? <div className="flex w-full justify-content-end">
                    <Button label="Tüm Dokümanlar" className="p-button-sm p-button-text text-color" icon="pi pi-eye" iconPos="right" onClick={() => dialogToggle()} />
                </div>:null}
            </div>}>
                <div className='flex flex-column gap-2' style={{ minHeight: "205px" }}>
                    <Divider />
                    {dosyaAkis?.map((dosya, index) => (
                        <div key={index}>
                            <div className='px-3 -my-2 text-sm flex align-items-center'>
                                <a href='#' onClick={async () => await getDocument(dosya)} className='text-black-alpha-90 no-underline hover:underline flex justify-content-space align-content-center align-items-center w-full'>
                                    <i className="pi pi-file mr-1" />
                                    <span className="w-full">{dosya.adi.replace(dosya.uzanti, "")}</span>
                                    <span className='text-gray-600 text-sm'>{new Date(dosya.olusturulmaTarihi).toLocaleDateString("tr-TR")}</span>
                                </a>
                            </div>
                            <Divider />
                        </div>
                    ))}
                </div>
            </ShadowedCard>
            <Dialog visible={dialogVisible} onHide={() => {
                dialogVisible && dialogToggle();
                setSelectedFile(null);
            }} toggle={dialogToggle} style={{ width: "80vw" }} header="Yönetim ve Talimat Duyurular">
                <div className='flex flex-column'>
                    <div className='flex flex-row p-2 gap-2 surface-ground'>
                        <Button icon="pi pi-arrow-left" disabled={breadcrumb.length === 1} className='p-button-raised p-button-text p-button-plain p-button-sm surface-0' onClick={async () => {
                            if (breadcrumb.length === 1) {
                                await getFiles()
                            }
                            else if (breadcrumb.length > 1) {
                                await getFiles((breadcrumb[breadcrumb.length - 1].path.substring(0, breadcrumb[breadcrumb.length - 1].path.lastIndexOf("/") + 1)).slice(0, -1))
                            }
                            setSelectedFile(null);
                        }} />
                        <Button icon="pi pi-home" className='p-button-raised p-button-text p-button-plain p-button-sm surface-0' onClick={async () => {
                            await getFiles();
                            setSelectedFile(null);
                        }} />
                        <Button icon="pi pi-refresh" className='p-button-raised p-button-text p-button-plain p-button-sm surface-0' onClick={async () => {
                            if (breadcrumb.length === 1) {
                                await getFiles();
                            }
                            else {
                                var path = "";
                                for (var i = 1; i < breadcrumb.length; i++) {
                                    if (i === breadcrumb.length - 1)
                                        path += breadcrumb[i].label;
                                    else
                                        path += breadcrumb[i].label + "/";
                                }
                                await getFiles(path);
                                setSelectedFile(null);
                            }
                        }} />
                        <BreadCrumb model={breadcrumb} className="breadcrumb surface-0 py-0 flex flex-grow-1" />
                        <Button label="Görüntüle" icon="pi pi-eye" disabled={!(selectedFile !== null && (selectedFile?.uzanti !== "" && selectedFile?.extension !== ""))} iconPos="left" className='p-button-raised p-button-text p-button-plain p-button-sm surface-0'
                            onClick={() => getDocument()} />
                        <Button label="İndir" icon="pi pi-download" disabled={!(selectedFile !== null && (selectedFile?.uzanti !== "" && selectedFile?.extension !== ""))} iconPos="left" className='p-button-raised p-button-text p-button-plain p-button-sm surface-0'
                            onClick={() => downloadFile(String(selectedFile.tamAdi), selectedFile.uzanti, selectedFile.adi)} />
                    </div>
                    <Splitter style={{ height: '300px' }}>
                        <SplitterPanel minSize={15} size={15}>
                            <div className='w-full h-full p-0 m-0'>
                                <ScrollPanel style={{ height: '100%', width: '100%' }}>
                                    <Tree value={sortFolders(folders?.data)} selectionMode="single" onSelect={async (e) => {
                                        await getFiles(String(e.node.path));
                                        setSelectedFile(null);
                                    }} className='bg-transparent border-none p-0 text-sm' />
                                </ScrollPanel>
                            </div>
                        </SplitterPanel>
                        <SplitterPanel className="py-4" minSize={75} size={75}>
                            {(folders?.loading || files?.loading) ? <div className='flex h-full justify-content-center align-items-center'><ProgressSpinner /></div> : (
                                <ScrollPanel style={{ height: '100%', width: '100%' }}>
                                    <ContextMenu model={contextMenuItems} ref={cm} />
                                    <div className='flex flex-column select-none px-2'>
                                        {files?.data?.directories?.map((directory, index) => <div key={index} className={`flex w-full cursor-pointer${selectedFile === directory ? ' surface-ground' : ''}`} onClick={() => setSelectedFile(selectedFile !== directory ? directory : null)}
                                            onDoubleClick={async () => {
                                                var path = "";
                                                for (var i = 1; i < breadcrumb.length; i++) {
                                                    path += breadcrumb[i].label + "/";
                                                }
                                                await getFiles(path + directory.name);
                                                setSelectedFile(null);
                                            }}>
                                            <div className='flex flex-row gap-2 align-content-center align-items-center py-2'>
                                                <i className='pi pi-fw pi-folder text-md justify-content-center mx-auto' />
                                                <span className='justify-content-center mx-auto' style={{ wordBreak: 'break-all' }}>{directory?.name}</span>
                                            </div>
                                        </div>)}
                                        {files?.data?.files?.map((file, index) => <div key={index} className={`flex w-full cursor-pointer${selectedFile === file ? ' surface-ground' : ''}`} onClick={() => setSelectedFile(selectedFile !== file ? file : null)}
                                            onContextMenu={(e) => {
                                                if (selectedFile !== file)
                                                    setSelectedFile(file);

                                                cm.current.show(e);
                                            }}>
                                            <div className='flex flex-row gap-2 align-content-center align-items-center py-2'>
                                                <i className='pi pi-fw pi-file text-md justify-content-center mx-auto' />
                                                <span className='justify-content-center mx-auto' style={{ wordBreak: 'break-all' }}>{file?.adi}</span>
                                            </div>
                                        </div>)}
                                    </div>
                                </ScrollPanel>
                            )}
                        </SplitterPanel>
                    </Splitter>
                </div>
            </Dialog>
            {document !== null ? <Dialog visible={documentVisible} toggle={documentToggle} onHide={() => {
                documentVisible && documentToggle();
                setDocument(null);
            }} style={{ width: '80vw', height: '80vh' }} header={<div className="flex flex-row gap-2 align-content-center align-items-center">{document[0].fileName} <Button label="İndir" icon="pi pi-download" iconPos="left" className="p-button-sm p-button-raised p-button-text p-button-plain p-button-sm surface-0"
            onClick={async () => await downloadFile(String(document[0].tamAdi), document[0].uzanti, document[0].adi)} /></div>}>
                <DocViewer pluginRenderers={DocViewerRenderers} documents={document} config={{ header: { disableFileName: true, disableHeader: true, retainURLParams: false } }} />
            </Dialog> : null}
            <Dialog header="Özellikler" visible={ozelliklerVisible} style={{ width: '30vw' }} onHide={() => {
                ozelliklerVisible && ozelliklerToggle(false);
                setSelectedFile(null);
            }} closeOnEscape resizable={false} dismissableMask>
                <div className='flex flex-column'>
                    <div className='flex align-items-center gap-2'>
                        <h4>İsim: </h4>
                        <span>{selectedFile?.adi?.replace(selectedFile?.uzanti, "")}</span>
                    </div>
                    <Divider className='py-0 my-0' />
                    <div className='flex align-items-center gap-2'>
                        <h4>Tür: </h4>
                        <span>{selectedFile?.uzanti === "" ? "Klasör" : `${selectedFile?.uzanti?.slice(1)} Dosyası`}</span>
                    </div>
                    <Divider className='py-0 my-0' />
                    <div className='flex align-items-center gap-2'>
                        <h4>Yüklenme Zamanı: </h4>
                        <span>{new Date(selectedFile?.olusturulmaTarihi).toLocaleString("tr-TR")}</span>
                    </div>
                    <Divider className='py-0 my-0' />
                    <div className='flex align-items-center gap-2'>
                        <h4>Dosya Boyutu: </h4>
                        <span>{selectedFile?.boyut}</span>
                    </div>
                    <Divider className='py-0 my-0' />
                </div>
            </Dialog>
        </>
    );
};
export default YonetimTalimatDuyurular;