import React from 'react';
// PrimeReact
import { Avatar } from 'primereact/avatar';
// Hooks
import { useStore } from 'store';
// Config
import { KOLIN_LOGO, PLACARD } from 'Config';

const Header = () => {
    const { doLogout, loginData } = useStore();
    return (
        <div className="header flex" style={{ backgroundImage: `url('${PLACARD}')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '35vh' }}>
            <div className="min-w-full min-h-full bg-black-alpha-30 align-items-stretch flex justify-content-center">
                <div className='container flex justify-content-between py-5'>
                    <img src={KOLIN_LOGO} style={{ width: '150px', height: '60px' }} />
                    <div className='flex flex-column'>
                        <Avatar label={loginData?.adi?.split(" ").map(basHarf => basHarf.charAt(0))} shape="circle" size="large" className='mx-auto' style={{ backgroundColor: 'purple', color: '#ffffff' }} />
                        <span className='mt-1'>{loginData?.adi}</span>
                        <span className='text-center text-xs mt-2 cursor-pointer' onClick={async () => await doLogout(loginData?.ePosta)}>Çıkış Yap</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Header;