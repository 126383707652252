// Services
import api from "services/api";
// Config
import { FileExtension } from "Config";

export const downloadFile = async (path, extension, fileName) => {
    let mimeType = FileExtension[extension.replace(".", "")];
    await api().get(`downloadFile?path=${path}&mimeType=${mimeType}`, { responseType: 'blob' }).then((response) => {
        const href = URL.createObjectURL(response?.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
};