// React
import React, { useEffect, useState } from "react";
// PrimeReact
import { Divider } from "primereact/divider";
import { Tag } from "primereact/tag";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { Dialog } from "components/ui/Dialog";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Tooltip } from "primereact/tooltip";
import { ProgressSpinner } from 'primereact/progressspinner';
// Hooks
import { useFetch } from "hooks/useFetch";
import { useStore } from "store";
import { useToggle } from "hooks/useToggle";
// Components
import { ShadowedCard } from "components/common/ShadowedCard";
// Context
import { Context } from "utils/Context";
// Utils
import { dateToStringConverter } from "utils/functions/converters";
import { getDayBetweenTwoDays } from "utils/functions/calculate";
// Form
import DuyuruValidation from "components/forms/DuyuruValidation";
import isFormFieldValid from "utils/functions/isFormFieldValid";
// Helpers
import { dataHelper } from "utils/helpers";

const Duyurular = ({ duyuruList, loading = false }) => {
    // Context
    const context = React.useContext(Context);
    // Store
    const { create, update, remove, genericResult, loginData, collapsedBoxes } = useStore();
    // Variables
    const { open: listDialog, toggle: listDialogToggle } = useToggle();
    const { open: addDialog, toggle: addDialogToggle } = useToggle();
    const { open: updateDialog, toggle: updateDialogToggle } = useToggle();
    const [selectedItem, setSelectedItem] = useState(null);
    // Api
    const [duyuruListesi, getDuyuruListesi] = useFetch();
    // Form
    const postSuccessFunction = async () => {
        let _data = {
            ...formik.values,
            tarih: new Date().toISOString()
        };
        context.confirmDialog({
            message: "Duyuru yayınlanacak, onaylıyor musunuz?",
            acceptFunction: async () => await create(`yeniHaberDuyuru`, _data)
        });
    };
    const updateSuccessFunction = async () => {
        let _data = {
            ...formikUpdate.values,
            tarih: new Date().toISOString()
        };
        context.confirmDialog({
            message: "Duyuru güncellencek, onaylıyor musunuz?",
            acceptFunction: async () => await update(`haberDuyuruGuncelle`, _data)
        });;
    };
    const formik = DuyuruValidation(postSuccessFunction);
    const formikUpdate = DuyuruValidation(updateSuccessFunction);
    // DataTable Templates
    const islemTemplate = (data) => {
        return (
            <div className="flex gap-2">
                <Button className="p-button-sm p-button-warning" icon="pi pi-pencil" onClick={() => {
                    Object.entries(data)?.map(([key, value]) => formikUpdate.setFieldValue(key, value));
                    setSelectedItem(data);
                    updateDialogToggle();
                }} />
                <Button className="p-button-sm p-button-danger" icon="pi pi-trash" onClick={() => context.confirmDialog({
                    message: "Seçili duyuru silinecek, onaylıyor musunuz?",
                    acceptFunction: async () => await remove(`haberDuyuruSil?id=${data?.id}`)
                })} />
            </div>
        );
    };
    // Api Action Result
    useEffect(() => {
        if (genericResult?.statusCode === 200) {
            getDuyuruListesi(`getHaberDuyuruByTipAndLimit?limit=0&tip=D`);
            addDialog && addDialogToggle();
            updateDialog && updateDialogToggle();
            formik.resetForm();
            formikUpdate.resetForm();
        }
    }, [genericResult?.statusCode]);
    
    return (
        <ShadowedCard keys="Duyurular" title={loginData?.role === "E" ? <div className="flex justify-content-end">
            {/* <span className='text-left select-none text-elipsis-portal' style={{ fontSize: "1.5rem" }}>Duyurular</span> */}
            {collapsedBoxes["Duyurular"] ? <div className="w-auto flex">
                <Button label="Yeni" className="p-button-text text-color p-button-sm" icon="pi pi-plus" iconPos="right" onClick={() => addDialogToggle()} />
                <Button label="Listele" className="p-button-text text-color p-button-sm" icon="pi pi-list" iconPos="right" onClick={async () => {
                    await getDuyuruListesi(`getHaberDuyuruByTipAndLimit?limit=0&tip=D`);
                    listDialogToggle();
                }} />
            </div>:null}
        </div> : null //<span className='flex justify-content-start' style={{ fontSize: "1.5rem" }}>Duyurular</span>
        }>
            {loading ? (<div className='flex h-full justify-content-center align-items-center' style={{ minHeight: '200px' }}><ProgressSpinner /></div>)
                : <ScrollPanel style={{ width: '100%', height: '200px' }} className="overflow-hidden">
                    <div className='flex flex-column gap-2'>
                        {(duyuruList !== undefined && duyuruList !== null && duyuruList?.length !== 0) ? <Divider /> : null}
                        {(duyuruList !== undefined && duyuruList !== null) ? duyuruList?.map(duyuru => (
                            <div key={duyuru?.id}>
                                <div className='px-3 -my-2 text-sm flex flex-row gap-2 w-full align-items-center'>
                                    {getDayBetweenTwoDays(new Date(), new Date(duyuru?.tarih)) < 5 ? (
                                        <Tag value="yeni" severity='danger' style={{ maxHeight: "30px" }} className="select-none" />
                                    ) : null}
                                    <Tooltip target=".icerikDetay" />
                                    <a href={duyuru?.icerikLink?.length > 0 ? duyuru?.icerikLink : "#"} data-pr-tooltip={duyuru?.icerikDetay} target={duyuru?.icerikLink?.length > 0 ? "_blank" : "_self"} className='icerikDetay text-black-alpha-90 no-underline hover:underline w-full'>
                                        <span className="flex justify-content-between"><span className="text-left">{duyuru?.icerik}</span> <span className='text-gray-600 text-sm text-right'>{(new Date(duyuru?.tarih)).toLocaleDateString("tr-TR")}</span></span>
                                    </a>
                                </div>
                                <Divider />
                            </div>
                        )) : null}
                    </div>
                </ScrollPanel>}

            <Dialog visible={listDialog} toggle={listDialogToggle} header="Duyuru Listesi">
                <DataTable value={dataHelper(duyuruListesi?.data)} rows={10} paginator size="small" loading={duyuruListesi?.loading} lazy={duyuruListesi?.loading} stateStorage="session" dataKey="id" responsiveLayout="scroll" showGridlines stripedRows columnResizeMode="fit">
                    <Column className="col-2" header="Başlık" field="icerik" />
                    <Column className="col-7" header="Açıklama" field="icerikDetay" />
                    <Column className="col-2" header="Tarih" field="tarih" body={body => dateToStringConverter(body.tarih)} />
                    <Column className="col-1" header="İşlem" body={islemTemplate} />
                </DataTable>
            </Dialog>
            <Dialog visible={addDialog} toggle={addDialogToggle} header="Duyuru Ekle" contentClassName="py-5">
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="field">
                        <span className="p-float-label">
                            <InputText id="icerik" name="icerik" placeholder="Duyuru Başlığı" className="p-inputtext-sm" onChange={formik.handleChange} value={formik.values.icerik} />
                            <label htmlFor="icerik" className={classNames({ 'p-error': isFormFieldValid(formik, "icerik") })}>Duyuru Başlığı*</label>
                        </span>
                    </div>
                    <div className="field py-2">
                        <span className="p-float-label">
                            <InputText id="icerikLink" name="icerikLink" placeholder="Link" className="p-inputtext-sm" onChange={formik.handleChange} value={formik.values.icerikLink} />
                            <label htmlFor="icerikLink" className={classNames({ 'p-error': isFormFieldValid(formik, "icerikLink") })}>Link</label>
                        </span>
                    </div>
                    <div className="field">
                        <span className="p-float-label">
                            <InputTextarea id="icerikDetay" rows={5} name="icerikDetay" placeholder="Açıklama*" className="p-inputtext-sm" onChange={formik.handleChange} value={formik.values.icerikDetay} />
                            <label htmlFor="icerikDetay" className={classNames({ 'p-error': isFormFieldValid(formik, "icerikDetay") })}>Açıklama*</label>
                        </span>
                    </div>
                    <div className="flex gap-2 justify-content-center">
                        <Button className="p-button-success w-auto" label="Tanımla" icon="pi pi-plus" iconPos="right" type="submit" />
                        <Button className="p-button-danger w-auto" label="İptal" icon="pi pi-times" iconPos="right" type="reset" onClick={() => {
                            formik.resetForm();
                            addDialog && addDialogToggle();
                        }} />
                    </div>
                </form>
            </Dialog>
            <Dialog visible={updateDialog} toggle={updateDialogToggle} onHide={() => setSelectedItem(null)} header="Duyuru Güncelle" contentClassName="py-5">
                <form onSubmit={formikUpdate.handleSubmit} className="p-fluid">
                    <div className="field">
                        <span className="p-float-label">
                            <InputText id="icerik" name="icerik" placeholder="Duyuru Başlığı" className="p-inputtext-sm" onChange={formikUpdate.handleChange} value={formikUpdate.values.icerik} />
                            <label htmlFor="icerik" className={classNames({ 'p-error': isFormFieldValid(formikUpdate, "icerik") })}>Duyuru Başlığı*</label>
                        </span>
                    </div>
                    <div className="field py-2">
                        <span className="p-float-label">
                            <InputText id="icerikLink" name="icerikLink" placeholder="Link" className="p-inputtext-sm" onChange={formikUpdate.handleChange} value={formikUpdate.values.icerikLink} />
                            <label htmlFor="icerikLink" className={classNames({ 'p-error': isFormFieldValid(formikUpdate, "icerikLink") })}>Link</label>
                        </span>
                    </div>
                    <div className="field">
                        <span className="p-float-label">
                            <InputTextarea id="icerikDetay" rows={5} name="icerikDetay" placeholder="Açıklama*" className="p-inputtext-sm" onChange={formikUpdate.handleChange} value={formikUpdate.values.icerikDetay} />
                            <label htmlFor="icerikDetay" className={classNames({ 'p-error': isFormFieldValid(formikUpdate, "icerikDetay") })}>Açıklama*</label>
                        </span>
                    </div>
                    <div className="flex gap-2 justify-content-center">
                        <Button className="p-button-success w-auto" label="Güncelle" icon="pi pi-refresh" iconPos="right" type="submit" />
                        <Button className="p-button-danger w-auto" label="Temizle" icon="pi pi-trash" iconPos="right" type="button" onClick={() => {
                            formikUpdate.setValues(selectedItem);
                        }} />
                    </div>
                </form>
            </Dialog>
        </ShadowedCard>
    );
};
export default Duyurular;