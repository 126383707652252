import React, { useEffect, useState, useRef } from 'react'
// PrimeReact
import { Card } from "primereact/card";
// Hooks
import { useStore } from 'store';
// Utils
import isExistInLocalStorage from 'utils/functions/isExistInLocalStorage';

export const ShadowedCard = ({ title, keys, children, backgroundColor = '#F0EFF4' }) => {
    const [isDragging, setDragging] = useState(false);
    const [collapsed, setCollapsed] = useState(true);
    const { loginData, setCollapsedBoxes } = useStore();
    useEffect(() => {
        if (!isExistInLocalStorage(`${loginData?.ePosta} - ${keys}`)) {
            localStorage.setItem(`${loginData?.ePosta} - ${keys}`, true);
            setCollapsedBoxes(keys, true);
        }
        else {
            setCollapsed(localStorage.getItem(`${loginData?.ePosta} - ${keys}`) === "true" ? true : false)
            setCollapsedBoxes(keys, localStorage.getItem(`${loginData?.ePosta} - ${keys}`) === "true" ? true : false);
        }
    }, []);

    const setCollapse = (value) => {
        setCollapsed(value);
        setCollapsedBoxes(keys, value);
        if (!isExistInLocalStorage(`${loginData?.ePosta} - ${keys}`)) {
            localStorage.setItem(`${loginData?.ePosta} - ${keys}`, value);
        }
        else {
            localStorage.setItem(`${loginData?.ePosta} - ${keys}`, value);
        }
    }
    return (
        <div className='relative'>
            <div style={{ backgroundColor: '#d7d7d7', height: "35px", width: "100%", position: "absolute", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
            <div className='absolute' style={{ top: collapsed ? "25%" : "25%", width: "85%", left: "6%" }}>
                <div className='flex justify-content-between'>
                <span className='text-left select-none text-elipsis-portal font-semibold' style={{ fontSize: "1.25rem", display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100%" }}>{keys}</span>
                {!collapsed ? <i className="pi pi-stop cursor-pointer" onClick={() => setCollapse(true)} /> : <i className="pi pi-minus cursor-pointer" onClick={() => setCollapse(false)} />}
                </div>
            </div>
            <div className='absolute' style={{ right: "3%", top: collapsed ? "25%" : "25%" }}
                draggable
                onDragStart={() => setDragging(true)}
                onDragEnd={() => setDragging(false)}>
                <i className={`move-icon${isDragging ? " cursor-move" : " cursor-pointer"}`} style={{ backgroundImage: 'url("./images/move_icon.svg")' }} />
            </div>
            </div>
            <Card title={title} className='col-12 md:col-12 lg:col card-shadow' style={{ backgroundColor: backgroundColor, height: collapsed ? "400px" : "35px" }}>
                {collapsed ? children : null}
            </Card>
        </div>
    );
};