// React
import React, { useEffect, useState } from 'react';
// Layout
import Layout from 'components/layout';
// Components
import InteractiveUygulamalar from './InteractiveUygulamalar';
import Duyurular from './Duyurular';
import DogumGunleri from './DogumGunleri';
import EntegreYonetimSistemi from "./EntegreYonetimSistemi";
import EgitimDokumanlari from './EgitimDokumanlari';
import YonetimTalimatDuyurular from './YonetimTalimatDuyurular';
import KurumsalIletisim from "./KurumsalIletisim";
import EntegreYonetimSistemi2 from "./EntegreYonetimSistemi2";
// Hooks
import { useFetch } from 'hooks/useFetch';
import { useStore } from 'store';
// Config
import { IZIN_URL } from 'Config'
// Helpers
import { dataHelper } from 'utils/helpers';
import SuBilgiKaliteDokumanlari from "./SuBilgiKaliteDokumanlari";

const Dashboard = () => {
    // Store
    const { loginData, dashboardOrder, setDashboardOrder } = useStore();
    // Api
    const [dogumGunleri, getDogumGunleri] = useFetch();
    const [dosyaAkis, getDosyaAkis] = useFetch();
    const [dosyaAkis2, getDosyaAkis2] = useFetch();
    const [dosyaAkis3, getDosyaAkis3] = useFetch();
    const [dosyaAkis4, getDosyaAkis4] = useFetch();
    const [dosyaAkis5, getDosyaAkis5] = useFetch();
    const [dosyaAkis6, getDosyaAkis6] = useFetch();
    const [duyuruList, getDuyuruList] = useFetch();
    const [haberList, getHaberList] = useFetch();
    const [draggedObject, setDraggedObject] = useState(null);
    // On Page Render
    const onPageRender = React.useCallback(async () => {
        await getDosyaAkis(`getDosyaAkisByLimit?path=Dosya&limit=5`);
        await getDosyaAkis2(`getDosyaAkisByLimit?path=Dosya-EgitimDokuman&limit=5`);
        await getDosyaAkis3(`getDosyaAkisByLimit?path=Dosya-YonetimDuyuru&limit=5`);
        await getDosyaAkis4(`getDosyaAkisByLimit?path=Kurumsal-Iletisim&limit=5`);
        await getDosyaAkis5(`getDosyaAkisByLimit?path=KologluEYS&limit=5`);
        await getDosyaAkis6(`getDosyaAkisByLimit?path=SuBT&limit=5`);
        await getDogumGunleri(`getDogumGunleri?domain=${loginData.domain}`);
        await getDuyuruList(`getHaberDuyuruByTipAndLimit?tip=D&limit=5`);
    });
    const onSuccessFunction = React.useCallback(async () => {
        await getDuyuruList(`getHaberDuyuruByTipAndLimit?tip=D&limit=5`);
    });
    useEffect(() => {
        onPageRender();
    }, []);

    const Components = (loginData, IZIN_URL, duyuruList, /*haberList,*/ dogumGunleri, dosyaAkis, dosyaAkis2, dosyaAkis3, dosyaAkis4, dosyaAkis5, dosyaAkis6) => {
        return {
            'İnteraktif': <InteractiveUygulamalar loginData={loginData} IZIN_URL={IZIN_URL} />,
            'Duyuru': <Duyurular duyuruList={dataHelper(duyuruList?.data)} loading={duyuruList?.loading} />,
            'KurumsalIletisim': <KurumsalIletisim dosyaAkis={dataHelper(dosyaAkis4?.data)} />,
            "DogumGunleri": <DogumGunleri dogumGunleri={dataHelper(dogumGunleri?.data)} loading={dogumGunleri?.loading} />,
            "EntegreYonetimSistemi": <EntegreYonetimSistemi dosyaAkis={dataHelper(dosyaAkis?.data)} />,
            "EntegreYonetimSistemi2": <EntegreYonetimSistemi2 dosyaAkis={dataHelper(dosyaAkis5?.data)} />,
            "EgitimDokumanlari": <EgitimDokumanlari dosyaAkis={dataHelper(dosyaAkis2?.data)} />,
            "YonetimTalimatDuyurular": <YonetimTalimatDuyurular dosyaAkis={dataHelper(dosyaAkis3?.data)} />,
            "SuBilgiKaliteDokumanlari": <SuBilgiKaliteDokumanlari dosyaAkis={dataHelper(dosyaAkis6?.data)} />,
        }
    }

    const sortComponents = (data) => Object.keys(data).sort(function (a, b) { return data[a] - data[b] });

    return (
        <Layout successFunction={onSuccessFunction}>
            <div className='col-12'>
                <div className='grid mx-auto'>
                    <div className='xs:col-12 sm:col-12 md:col-6 col-6'>
                        <div className='grid'>
                            {sortComponents(dashboardOrder).map((item, index) => {
                                if (index % 2 === 0) {
                                    return <div className={`col-12 py-3`} key={item} id={item}
                                        onDragOver={e => {
                                            e.preventDefault();
                                            setDraggedObject(e.currentTarget?.id)
                                        }}
                                        onDragEnd={() => {
                                            if (item !== draggedObject) {
                                                let first = dashboardOrder[item];
                                                let second = dashboardOrder[draggedObject];

                                                setDashboardOrder({
                                                    ...dashboardOrder,
                                                    [item]: second,
                                                    [draggedObject]: first
                                                });
                                            }
                                        }}
                                    >
                                        {Components(loginData, IZIN_URL, duyuruList, /*haberList,*/ dogumGunleri, dosyaAkis, dosyaAkis2, dosyaAkis3, dosyaAkis4, dosyaAkis5, dosyaAkis6)[item]}
                                    </div>
                                }
                            })}
                        </div>
                    </div>
                    <div className='xs:col-12 sm:col-12 md:col-6 col-6'>
                        <div className='grid'>
                            {sortComponents(dashboardOrder).map((item, index) => {
                                if (index % 2 !== 0) {
                                    return <div className={`col-12 py-3`} key={item} id={item}
                                        onDragOver={e => {
                                            e.preventDefault();
                                            setDraggedObject(e.currentTarget?.id)
                                        }}
                                        onDragEnd={() => {
                                            if (item !== draggedObject) {
                                                let first = dashboardOrder[item];
                                                let second = dashboardOrder[draggedObject];

                                                setDashboardOrder({
                                                    ...dashboardOrder,
                                                    [item]: second,
                                                    [draggedObject]: first
                                                });
                                            }
                                        }}
                                    >
                                        {Components(loginData, IZIN_URL, duyuruList, /*haberList,*/ dogumGunleri, dosyaAkis, dosyaAkis2, dosyaAkis3, dosyaAkis4, dosyaAkis5, dosyaAkis6)[item]}
                                    </div>
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default Dashboard;