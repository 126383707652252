//React
import React, { useRef } from "react";
// Config
import { BACKGROUND1 } from "Config";
// Pages
import LoginPage from "./LoginPage";
// Styles
const container = {
    width: "100vw",
    height: "100vh",
    overflow: "hidden"
}
const pagesParent = {
    display: "flex",
    width: "100vw",
}
const pages = {
    display: "flex",
    width: "100%",
    boxSizing: "border-box"
}
const pageContainer = {
    overflow: "hidden",
}
const page = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    transition: "all 0.7s",
    color: "white",
    background: 'url(' + BACKGROUND1 + ')',
    backgroundPosition: "center",
    backgroundPepeat: "no-repeat",
    backgroundSize: "cover"
}
const page01 = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    transition: "all 0.7s"
}
const Login = () => {
    // Refs
    const page1 = useRef(null);
    // Render
    return (
        <div style={container}>
            <div style={pagesParent}>
                <div style={page}>
                    <div className="flex flex-column justify-content-center bg-black-alpha-60 w-9 xs:w-9 sm:w-9 md:w-9 lg:w-3 xl:w-3 border-round-sm shadow-4 py-6 px-0">
                        <div style={pageContainer}>
                            <div style={pages}>
                                <LoginPage ref={page1} style={page01} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Login;