// React
import React, { useEffect, useRef } from "react";
// PrimeReact
import { Divider } from 'primereact/divider';
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { Dialog } from "components/ui/Dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
// Context
import { Context } from "utils/Context";
// Hooks
import { useToggle } from "hooks/useToggle";
import { useFetch } from "hooks/useFetch";
import { useStore } from "store";
// Config
import { FOOTER } from "Config";
// Forms
import SirketValidation from "components/forms/SirketValidation";
import isFormFieldValid from "utils/functions/isFormFieldValid";
// Helpers
import { dataHelper } from "utils/helpers";
// Utils
import { convertBase64 } from "utils/functions/converters";
// Data
// import sirketler from "assets/data/sirketler.json";

const Footer = () => {
    // Context
    const context = React.useContext(Context);
    // Store
    const { genericResult, create, update, remove, loginData } = useStore();
    // Variables
    const inputRef = useRef(null);
    const { open: addDialog, toggle: addDialogToggle } = useToggle();
    const { open: updateDialog, toggle: updateDialogToggle } = useToggle();
    const { open: listDialog, toggle: listDialogToggle } = useToggle();
    // Api
    const [sirketler, getSirketler] = useFetch();
    // On Page Render
    const onPageRender = React.useCallback(async () => {
        await getSirketler(`getAllSirket`);
    });
    useEffect(() => {
        onPageRender();
    }, []);
    // DataTable Templates
    const islemTemplate = (data) => {
        return (
            <div className="flex gap-2">
                <Button className="p-button-sm p-button-warning" icon="pi pi-pencil" onClick={() => {
                    Object.entries(data)?.map(([key, value]) => formikUpdate.setFieldValue(key, value));
                    updateDialogToggle();
                }} />
                <Button className="p-button-sm p-button-danger" icon="pi pi-trash" onClick={() => context.confirmDialog({
                    message: "Seçili şirket silinecek, onaylıyor musunuz?",
                    acceptFunction: async () => await remove(`sirketSil?id=${data?.id}`)
                })} />
            </div>
        );
    };
    // Forms
    const postSuccessFunction = async () => {
        let _data = {
            ...formik.values
        };
        context.confirmDialog({
            message: "Şirket kaydedilecek, onaylıyor musunuz?",
            acceptFunction: async () => await create(`yeniSirket`, _data)
        });
    };
    const updateSuccessFunction = async () => {
        let _data = {
            ...formikUpdate.values
        };
        context.confirmDialog({
            message: "Şirket güncellenecek, onaylıyor musunuz?",
            acceptFunction: async () => await update(`sirketGuncelle`, _data)
        });
    };
    const formik = SirketValidation(postSuccessFunction);
    const formikUpdate = SirketValidation(updateSuccessFunction);
    // Api Action Result
    useEffect(() => {
        if (genericResult?.statusCode === 200) {
            onPageRender();
            addDialog && addDialogToggle();
            updateDialog && updateDialogToggle();
            formik.resetForm();
            formikUpdate.resetForm();
        }
    }, [genericResult?.statusCode]);
    // Form Actions
    const handleLogoUpload = async (e, formik) => {
        if (e.target.files.length) {
            await convertBase64(e.target.files[0]).then(data => {
                formik.setFieldValue('logo', data);
            });
        }
        e.target.value = null;
    }

    return (
        <div className="footer flex justify-content-center align-content-center align-items-center">
            <div className='container py-4 relative'>
                {loginData?.role === "E" ? (
                        <div className="absolute right-0" style={{ top: "2%" }}>
                            <Button label="Şirket Ekle" icon="pi pi-plus" className="p-button-sm p-button-text text-white text-xs" iconPos="right" onClick={() => addDialogToggle()} />
                            <Button label="Şirketleri Listele" icon="pi pi-list" className="p-button-sm p-button-text text-white text-xs" iconPos="right" onClick={() => listDialogToggle()} />
                        </div>
                    ) : null}
                <div className={`grid${loginData?.role === "E" ? " mt-2" : ""}`}>
                    <a href='https://www.linkedin.com/company/kolin-construction-tourism-industry-and-trading-co-inc/' target="_blank" className='no-underline col' style={{ color: '#f5a800' }}><i className='text-4xl pi pi-linkedin' /></a>
                    <div className="col-11">
                        <ScrollPanel style={{ width: "100%", height: "200px" }}>
                            <div className="grid">
                                {sirketler?.data?.length > 0 && sirketler?.data?.map((sirket, index) => <div className="xs:col-12 md:col-6 col-12" key={index}>
                                    <a className="flex flex-row gap-2 aling-content-center align-items-center text-0 no-underline hover:underline text-xs" href={sirket?.websitesi} target="_blank">
                                        <div style={{ minWidth: "50px", backgroundColor: "grey", display: "flex", justifyContent: "center" }}>
                                            <img src={sirket?.logo} style={{ objectFit: "fill", maxWidth: "50px", maxHeight: "20px" }} />
                                        </div>
                                        <span>{sirket?.adi}</span>
                                    </a>
                                </div>)}
                            </div>
                        </ScrollPanel>
                    </div>
                </div>
                <Divider />
                <div className="flex">
                    <span className='mx-auto text-center'>{FOOTER}</span>
                </div>
            </div>
            <Dialog visible={listDialog} toggle={listDialogToggle} header="Şirket Listesi">
                <DataTable value={dataHelper(sirketler?.data)} rows={10} paginator size="small" loading={sirketler?.loading} lazy={sirketler?.loading} stateStorage="session" dataKey="id" responsiveLayout="scroll" showGridlines stripedRows columnResizeMode="fit">
                    <Column className="col-3" header="Logo" field="logo" body={data => <img src={data?.logo} style={{ width: '30px', height: '20px' }} />} />
                    <Column className="col-8" header="Adı" field="adi" />
                    <Column className="col-1" header="İşlem" body={islemTemplate} />
                </DataTable>
            </Dialog>
            <Dialog visible={addDialog} toggle={addDialogToggle} header="Şirket Ekle" contentClassName="py-5">
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="field">
                        <span className="p-float-label">
                            <InputText id="adi" name="adi" placeholder="Şirket Adı*" className="p-inputtext-sm" onChange={formik.handleChange} value={formik.values.adi} />
                            <label htmlFor="adi" className={classNames({ 'p-error': isFormFieldValid(formik, "adi") })}>Şirket Adı*</label>
                        </span>
                    </div>
                    <div className="field flex gap-2">
                        <span className="p-float-label w-9">
                            <InputText id="websitesi" name="websitesi" placeholder="Link" className="p-inputtext-sm" onChange={formik.handleChange} value={formik.values.websitesi} />
                            <label htmlFor="websitesi" className={classNames({ 'p-error': isFormFieldValid(formik, "websitesi") })}>Link</label>
                        </span>
                        <input type="file" className="hidden" ref={inputRef} accept="image/*" onChange={e => handleLogoUpload(e, formik)} multiple={false} />
                        <Button className={`p-button-primary p-button-sm w-${formik.values.logo !== null ? '2' : '3'}`} label="Logo Yükle" icon="pi pi-upload" iconPos="right" type="button" onClick={() => inputRef.current.click()} />
                        <Button className={`p-button-danger p-button-sm ${formik.values.logo !== null ? 'w-1' : 'hidden'}`} icon="pi pi-times" type="button" onClick={() => formik.setFieldValue('logo', null)} />
                    </div>
                    <div className="flex gap-2 justify-content-center">
                        <Button className="p-button-success w-auto" label="Tanımla" icon="pi pi-plus" iconPos="right" type="submit" />
                        <Button className="p-button-danger w-auto" label="İptal" icon="pi pi-times" iconPos="right" type="reset" onClick={() => {
                            formik.resetForm();
                            addDialog && addDialogToggle();
                        }} />
                    </div>
                </form>
            </Dialog>
            <Dialog visible={updateDialog} toggle={updateDialogToggle} header="Şirket Güncelle" contentClassName="py-5">
                <form onSubmit={formikUpdate.handleSubmit} className="p-fluid">
                    <div className="field">
                        <span className="p-float-label">
                            <InputText id="adi" name="adi" placeholder="Şirket Adı*" className="p-inputtext-sm" onChange={formikUpdate.handleChange} value={formikUpdate.values.adi} />
                            <label htmlFor="adi" className={classNames({ 'p-error': isFormFieldValid(formikUpdate, "adi") })}>Şirket Adı*</label>
                        </span>
                    </div>
                    <div className="field flex gap-2">
                        <span className="p-float-label w-9">
                            <InputText id="websitesi" name="websitesi" placeholder="Link" className="p-inputtext-sm" onChange={formikUpdate.handleChange} value={formikUpdate.values.websitesi} />
                            <label htmlFor="websitesi" className={classNames({ 'p-error': isFormFieldValid(formikUpdate, "websitesi") })}>Link</label>
                        </span>
                        <input type="file" className="hidden" ref={inputRef} accept="image/*" onChange={e => handleLogoUpload(e, formikUpdate)} multiple={false} />
                        <Button className={`p-button-primary p-button-sm w-${formikUpdate.values.logo !== null ? '2' : '3'}`} label="Logo Yükle" icon="pi pi-upload" iconPos="right" type="button" onClick={() => inputRef.current.click()} />
                        <Button className={`p-button-danger p-button-sm ${formikUpdate.values.logo !== null ? 'w-1' : 'hidden'}`} icon="pi pi-times" type="button" onClick={() => formikUpdate.setFieldValue('logo', null)} />
                    </div>
                    <div className="flex gap-2 justify-content-center">
                        <Button className="p-button-success w-auto" label="Güncelle" icon="pi pi-refresh" iconPos="right" type="submit" />
                        <Button className="p-button-danger w-auto" label="İptal" icon="pi pi-times" iconPos="right" type="reset" onClick={() => {
                            formikUpdate.resetForm();
                            addDialog && addDialogToggle();
                        }} />
                    </div>
                </form>
            </Dialog>
        </div>
    );
};
export default Footer;