import { useState } from "react";
// Api
import api from "services/api";
// Store
import { useStore } from "store";

export const useFetch = () => {
    // Variables
    const { reset, loginData } = useStore();
    // Exported Variables
    const [isDone, setIsDone] = useState(false);
    const [result, setResult] = useState({});
    const [loading, setLoading2] = useState(false);
    const [statusCode, setStatusCode] = useState(0);
    const [fetched, setFetched] = useState(false);
    // Actions
    const queryFunction = async (url) => {
        setIsDone(false);
        setResult({});
        setStatusCode(0);
        setFetched(false);
        setLoading2(true);
        try {
            const res = await api(loginData?.token).get(url);
            if (await res.data) {
                setResult(res.data.result);
                setStatusCode(res.data.statusCode);
                setFetched(true);
            }
            else {
                setResult(res);
                setStatusCode(res.status);
            }
            setIsDone(true);
            if(res.status === 401)
                reset();
        }
        catch (err) {
            setIsDone(true);
            setLoading2(false);
            setResult(err?.response?.data);
            setStatusCode(err.response ? err.response.status : 0);
            if(err?.response?.status === 401)
                reset();
        }
        finally {
            setIsDone(true);
            setLoading2(false);
        }
    };
    const clear = () => {
        setResult({});
        setStatusCode(0);
        setFetched(false);
        setLoading2(false);
        setIsDone(false);
    }
    // Output
    return [
        {
            data: result,
            loading: loading,
            statusCode: statusCode,
            fetched: fetched,
            isDone: isDone,
            clear: clear
        },
        queryFunction
    ];
};