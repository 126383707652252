// React
import React, { useEffect, useState } from 'react';
// Services
import api from 'services/api';
// Context
import { Context } from 'utils/Context';
// Hooks
import { useStore } from 'store';
// Utils
import { getCookie, isCookieExist, setCookie } from 'utils/functions/cookies';
import isExistInLocalStorage from 'utils/functions/isExistInLocalStorage';
// Helpers
import { apiHelper, jwtHelper } from 'utils/helpers';
// Config
import { authState, BASENAME, LOGIN_TIMEOUT } from 'Config';

export const useAuth = () => {
  // Store
  const { auth, loginData, setLoginData, setAuth, setDashboardOrder, DashboardOrder, reset } = useStore();
  // Context
  const context = React.useContext(Context);
  // Show Page
  const [show, setShow] = useState(false);
  const doLogin = async ({ eposta, parola }) => {
    setAuth(authState.inProgress);
    await apiHelper(async () => await api().post(`login`, { eposta, parola })).then(async (res) => {
      if (res?.statusCode === 200) {
        let value = {
          ...res,
          adiSoyadi: `${res?.adi} ${res?.soyadi}`,
          domain: res?.claims?.find(c => c.type === 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/system')?.value,
          role: res?.claims?.find(c => c.type === "http://schemas.microsoft.com/ws/2008/06/identity/claims/role")?.value
        };
        setLoginData(value);

        context.newMessage('success', 'Başarılı!', 'Başarıyla giriş yaptınız, uygulamaya yönlendiriliyorsunuz...', LOGIN_TIMEOUT);
        setCookie("tokens", value?.token);

        setTimeout(() => setAuth(authState.loggedIn), LOGIN_TIMEOUT);
      }
      else {
        context.newMessage('error', 'Hata!', res.errors[0], 3500);
        setLoginData({
          statusCode: res.statusCode,
          error: res.errors[0]
        });
        setAuth(authState.notLoggedIn);
      }
    });
  };
  // Reloading LoginData
  useEffect(() => {
    if (Object.keys(loginData)?.length === 0) {
      if (isCookieExist("tokens")) {
        setAuth(authState.reLoading);
        const jwt = jwtHelper(getCookie("tokens"));
        const newLoginData = {
          id: jwt.id,
          adi: jwt.givenName,
          claims: jwt.claims,
          domain: jwt.domain,
          token: jwt.token,
          ePosta: jwt.email,
          group: jwt.group,
          role: jwt.role
        };
        setLoginData(newLoginData);
        setAuth(authState.loggedIn);
        setShow(true);
      }
      else {
        setShow(true);
      }
    }
    else {
      setShow(true);
      if (auth === authState.notLoggedIn)
        reset();
    }
  }, [loginData]);
  return { doLogin: doLogin, auth: auth, role: loginData?.role, showPage: show };
}