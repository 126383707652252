export const dataHelper = (inp) => {
    if(inp) {
        try{
            return inp.map((item) => ({
                ...item
            }));
        }
        catch{
            return [];
        }
    }
    else {
        return [];
    }
};