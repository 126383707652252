// React
import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// Components
import LoadingPage from 'components/ui/LoadingPage';
// Hooks
import { useAuth } from 'hooks/useAuth';
// Config
import { authState, IS_DEVELOPMENT_MODE, LOGINPAGE } from 'Config';

const ProtectedRoutes = () => {
    const { auth } = useAuth();
    useEffect(() => {
        if(!IS_DEVELOPMENT_MODE){
            console.clear();
        }
    }, [console]);
    return (auth === authState.reLoading && auth !== authState.notLoggedIn)
        ? <LoadingPage />
        : auth === authState.loggedIn
            ? <Outlet />
            : <Navigate to={LOGINPAGE} replace state={{ from: null }} />;
};
export default ProtectedRoutes;