// React
import React from 'react';
// Form Validation
import * as yup from 'yup';
import { useFormik } from 'formik';
// Context
import { Context } from 'utils/Context';

const KullaniciValidation = (onSubmitFunction) => {
    const context = React.useContext(Context);
    const validationSchema = yup.object().shape({
        ePosta: yup.string().email().nullable(async () => await context.newMessage("warn", "Hata", "E-postayı doğru giriniz")),
        adi: yup.string().required(async () => await context.newMessage("warn", "Hata", "Ad boş geçilemez")),
        soyadi: yup.string().required(async () => await context.newMessage("warn", "Hata", "Soyad boş geçilemez")),
        dogumTarihi: yup.date().required(async () => await context.newMessage("warn", "Hata", "Doğum tarihi boş geçilemez")),
        admin: yup.string().nullable(),
    });
    return useFormik({
        initialValues: {
            id: "",
            ePosta: "",
            adi: "",
            soyadi: "",
            dogumTarihi: null,
            admin: "H"
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: () => {
            onSubmitFunction();
        }
    });
}
export default KullaniciValidation;