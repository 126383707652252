import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
// PrimeReact
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
// Style
import './index.css';
// App.js
import App from './App';
// SuBilgi Provider
import { SuBilgi } from 'utils/SuBilgi';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <SuBilgi>
      <div className="wrapper">
        <App />
      </div>
    </SuBilgi>
  </Router>
);