// Zustand
import { create } from "zustand";
// Slices
import { loginSlice } from "./loginSlice";
import { genericSlice } from "./genericSlice";
// LogoutFn
import { logoutFn } from "utils/functions/auth";

export const useStore = create((set, get) => ({
    ...genericSlice(set, get),
    ...loginSlice(set, get),
    reset: () => {
        set(() => ({
            ...genericSlice(set, get),
            ...loginSlice(set, get),
        }));
        logoutFn();
    }
}));