// React
import React from 'react';
// Form Validation
import * as yup from 'yup';
import { useFormik } from 'formik';
// Context
import { Context } from 'utils/Context';

const SirketValidation = (onSubmitFunction) => {
    const context = React.useContext(Context);
    const validationSchema = yup.object().shape({
        adi: yup.string().min(10).required(async () => await context.newMessage("warn", "Hata", "Şirket adı en az 10 karakter olmalıdır")),
        websitesi: yup.string().min(5).nullable(async () => await context.newMessage("warn", "Hata", "Link en az 5 karakter olmalıdır")),
    });
    return useFormik({
        initialValues: {
            id: "",
            adi: "",
            websitesi: "",
            logo: null,
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: () => {
            onSubmitFunction();
        }
    });
}
export default SirketValidation;