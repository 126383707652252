// React
import React, { useEffect } from "react";
// Components
import Header from "./Header";
import Footer from "./Footer";
// Hooks
import { useStore } from "store";
// Context
import { Context } from "utils/Context";

const Layout = ({ children, successFunction = null, errorFunction = null }) => {
    // Store
    const { genericResult, clearGenericResult } = useStore();
    // Context
    const context = React.useContext(Context);
    // Toast Mesaj
    // Toast Mesajları
    useEffect(() => {
        if (genericResult?.statusCode === 200) {
            const message = genericResult?.result;
            clearGenericResult();
            context.newMessage("success", "Başarılı!", message);
            successFunction && successFunction();
        }
        else if (genericResult?.statusCode !== 0) {
            const errors = genericResult?.errors;
            clearGenericResult();
            context.newMessages(errors);
            errorFunction && errorFunction();
        }
    }, [genericResult?.statusCode]);
    return (
        <div className="flex align-items-start">
            <div className="w-full">
                <Header />
                <div className="page-wrap page-content flex">
                    <div className='container py-4 gap-3 flex flex-column'>
                        {children}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};
export default Layout;