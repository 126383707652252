// React
import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
// Config
import { HOMEPAGE, LOGINPAGE, authState } from "Config";
// Components
import ProtectedRoutes from "components/common/ProtectedRoutes";
// Hooks
import { useAuth } from "hooks/useAuth";
/* Pages */
// Login
import Login from "pages/Login";
// Dashboard
import Dashboard from "pages/Dashboard";

const App = () => {
  const { auth } = useAuth();

  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        {/* Login */}
        <Route path="*" element={<Navigate to={auth === authState.notLoggedIn ? LOGINPAGE : HOMEPAGE} />} />
        <Route path={LOGINPAGE} element={<Login />} />
        {/* Tüm Personeller */}
        <Route element={<ProtectedRoutes />}>
          {/* Dashboard */}
          <Route path={HOMEPAGE} element={<Dashboard />} />
        </Route>
      </Route>
    </Routes>
  )
};
export default App;