import React from 'react'
import { ConfirmDialog as CD } from 'primereact/confirmdialog';

export const ConfirmDialog = ({ visible, toggle, accept, reject, onHide, message, ...props }) => {
    return (
        <CD header="Uyarı" icon="pi pi-exclamation-triangle"
            visible={visible} onHide={() => {
                onHide && onHide();
                visible && toggle();
            }}
            accept={() => {
                accept && accept();
                visible && toggle();
            }}
            reject={() => {
                reject && reject();
                visible && toggle();
            }}
            message={message}
            draggable={false} closeOnEscape={true} closable={true} resizable={false} style={{ minWidth: "50vw" }} {...props} />
    )
};