import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
// PrimeReact
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
// Hooks
import { useAuth } from 'hooks/useAuth';
// Config
import { APP_TITLE, HOMEPAGE, authState } from 'Config';

const LoginPage = ({ style }, ref) => {
    // Variables
    const [eposta, setEposta] = useState("");
    const [parola, setParola] = useState("");
    const navigate = useNavigate();
    const onSubmitFunction = async (e) => {
        e.preventDefault();
        await doLogin({
            eposta: eposta,
            parola: parola
        });
    };
    // Auth
    const { auth, doLogin } = useAuth();
    // Api Actions
    useEffect(() => {
        if(auth === authState.loggedIn) {
            // eslint-disable-next-line no-restricted-globals
            if(location?.state?.from?.pathname)
                // eslint-disable-next-line no-restricted-globals
                navigate(location?.state?.from?.pathname);
            else
                navigate(HOMEPAGE);
        }
    }, [auth]);
    // Render
    if(auth === authState.loggedIn)
        return <Navigate to="/" />
    else
        return (
            <div ref={ref} style={style}>
                <h2 className="text-center">Giriş Yap</h2>
                <h4 className="text-center">{APP_TITLE}</h4>
                <form className="px-5" autoComplete="off" onSubmit={onSubmitFunction}>
                    <div className="col-12">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-at"></i>
                            </span>
                            <InputText placeholder="E-Posta" autoComplete="off" name="username" value={eposta} onChange={e => setEposta(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-lock"></i>
                            </span>
                            <Password toggleMask feedback={false} placeholder="Parola" autoComplete="off" name="password" value={parola} onChange={e => setParola(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className='flex flex-column gap-2'>
                            <Button label="Giriş Yap" className="w-full" type="submit" />
                        </div>
                    </div>
                </form>
            </div>
        );
};
export default React.forwardRef(LoginPage); 