// Immer
import produce from "immer";
// Helpers
import { apiHelper } from "utils/helpers";
// Services
import api from "services/api";
// Config
import { BASENAME, authState } from "Config";
// Utils
import { loginHelpingFn } from "utils/functions/auth";
import { setCookie } from "utils/functions/cookies";
import isExistInLocalStorage from "utils/functions/isExistInLocalStorage";

export const loginSlice = (set, get) => ({
    // Personel bilgileri
    loginData: new Object(),
    dashboardOrder: {
        'İnteraktif': 0,
        "Duyuru": 1,
        "KurumsalIletisim": 2,
        "DogumGunleri": 3,
        "EntegreYonetimSistemi": 4,
        "EntegreYonetimSistemi2": 5,
        "EgitimDokumanlari": 6,
        "YonetimTalimatDuyurular": 7,
        "SuBilgiKaliteDokumanlari": 8,
    },
    setDashboardOrder: (order) => {
        let loginData = get().loginData;
        set(produce((state) => {
            state.dashboardOrder = order;
            if (isExistInLocalStorage(loginData?.ePosta)) {
                const _local = JSON.parse(localStorage.getItem(loginData?.ePosta));
                localStorage.setItem(loginData?.ePosta, JSON.stringify({
                    ..._local,
                    dashboardOrder: JSON.stringify(order)
                }));
            }
        }));
    },
    setLoginData: (value) => {
        set(produce((state) => {
            state.loginData = value;
            // Personelin uygulama tercihlerini yükler
            if (value?.ePosta){
                loginHelpingFn({ value: value, state: state });
                setCookie("tokens", value?.token);
            }
        }));
    },
    // Uygulamayı kullanan personelin uygulamaya girme durumu
    auth: authState.notLoggedIn,
    setAuth: (status) => {
        set(produce((state) => {
            state.auth = status;
        }));
    },
    reLoad: async (id, token) => {
        await apiHelper(async () => await api(token).get(`getPersonelById?id=${id}`).then((res) => {
            if (res?.data?.statusCode === 200) {
                set(produce((state) => {
                    state.loginData = {
                        ...res?.data?.result,
                        avatar: `${BASENAME}/images/avatars/${res?.data?.result.cinsiyet}.png`,
                        adiSoyadi: `${res?.data?.result.adi} ${res?.data?.result.soyadi}`,
                        token: token,
                    };
                    state.auth = authState.loggedIn;
                }));
                set(produce(state => {
                    loginHelpingFn({ value: res?.data?.result, state: state });
                }));
            }
            else {
                set(produce(state => {
                    state.auth = authState.notLoggedIn;
                }));
            }
        })).catch(() => get().reset());
    },
    doLogout: async (eposta) => {
        get().reset();
        await api().delete(`logout?eposta=${eposta}`);
    }
});