export const jwtHelper = (token) => {
    if((token && token !== "") === true){
        var idType = "nameidentifier";
        var base64Url = token.split('.')[1];
        if (base64Url) {
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
    
            let _parsedToken = JSON.parse(jsonPayload);
            let _id;
            let _givenName;
            let _email;
            let _domain;
            let _group;
            let _role;
            let _claims = [];
            for (var i in _parsedToken) {
                if (String(i).includes("claims")) {
                    let _output = {
                        issuer: "LOCAL AUTHORITY",
                        originalIssuer: "LOCAL AUTHORITY",
                        properties: {},
                        subject: null,
                        type: i,
                        value: _parsedToken[i],
                        valueType: "http://www.w3.org/2001/XMLSchema#string"
                    }
                    let value = String(i).split("/");
                    let _key = value[value.length - 1];
                    let _value = _parsedToken[i];
                    _claims[_key] = _value;
                    _claims.push(_output);
                    if (String(i).includes(idType))
                        _id = _parsedToken[i];
                    else if(String(i).includes("givenname"))
                        _givenName = _parsedToken[i];
                    else if(String(i).includes("email"))
                        _email = _parsedToken[i];
                    else if(String(i).includes("system"))
                        _domain = _parsedToken[i];
                    else if(String(i).includes("groupsid"))
                        _group = _parsedToken[i];
                    else if(String(i).includes("role"))
                        _role = _parsedToken[i];
                }
            }
            return {
                id: _id,
                claims: _claims,
                token: token,
                givenName: _givenName,
                email: _email,
                domain: _domain,
                group: _group,
                role: _role
            }
        }
    }
    else{
        return null;
    }
}