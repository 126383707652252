import { COOKIE_TIME_LIMIT } from "Config";

export const setCookie = (name, value, minute = null) => {
    var expires = "";
    if (minute) {
        let _time = new Date(new Date().getTime() + minute * 60 * 1000).toUTCString();
        expires = "; expires=" + _time;
    }
    else {
        let _time = new Date(new Date().getTime() + COOKIE_TIME_LIMIT * 60 * 1000).toUTCString();
        expires = "; expires=" + _time;
    }
    document.cookie = name + "=" + (value || "") + expires + "; Secure; SameSite=None; path=/;";
};

export const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const isCookieExist = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return true;
        }
    }
    return false;
}

export const removeCookie = (name) => {
    // eslint-disable-next-line no-restricted-globals
    document.cookie = name + "=" + ";path=/ ;Max-Age=0; domain=" + location.host + ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
};

export const deleteAllCookies = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        if (name.includes("remember"))
            continue;
        eraseCookieFromAllPaths(name);
    }
};
// eslint-disable-next-line no-restricted-globals
function eraseCookieFromAllPaths(name) {
    // eslint-disable-next-line no-restricted-globals
    var pathBits = location.pathname.split('/');
    var pathCurrent = ' path=';

    document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;';

    for (var i = 0; i < pathBits.length; i++) {
        pathCurrent += ((pathCurrent.substr(-1) != '/') ? '/' : '') + pathBits[i];
        document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;' + pathCurrent + ';';
    }
}

export const getCookies = function () {
    var pairs = document.cookie.split(";");
    var cookies = {};
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split("=");
        cookies[(pair[0] + '').trim()] = unescape(pair.slice(1).join('='));
    }
    return cookies;
}