// React
import React, { useEffect, useState, useRef } from "react";
// PrimeReact
import { Button } from "primereact/button";
import { Dialog } from "components/ui/Dialog";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { ProgressSpinner } from 'primereact/progressspinner';
// Hooks
import { useFetch } from "hooks/useFetch";
import { useStore } from "store";
import { useToggle } from "hooks/useToggle";
// Components
import { ShadowedCard } from "components/common/ShadowedCard";
// Context
import { Context } from "utils/Context";
// Utils
import { dateToStringConverter } from "utils/functions/converters";
// Form
import KullaniciValidation from "components/forms/KullaniciValidation";
import isFormFieldValid from "utils/functions/isFormFieldValid";
// Helpers
import { dataHelper } from "utils/helpers";

const DogumGunleri = ({ dogumGunleri, loading = false }) => {
    // Context
    const context = React.useContext(Context);
    // Store
    const { create, update, remove, genericResult, loginData, collapsedBoxes } = useStore();
    // Variables
    const ref = useRef(null);
    const { open: listDialog, toggle: listDialogToggle } = useToggle();
    const { open: addDialog, toggle: addDialogToggle } = useToggle();
    const { open: updateDialog, toggle: updateDialogToggle } = useToggle();
    const [selectedItem, setSelectedItem] = useState(null);
    // Api
    const [kullaniciListesi, getKullaniciListesi] = useFetch();
    // Form
    const toISOStringWithTimezone = (date) =>  new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0, -1);
    const postSuccessFunction = async () => {
        let _data = {
            ...formik.values,
            dogumTarihi: toISOStringWithTimezone(new Date(formik.values.dogumTarihi))
        };
        context.confirmDialog({
            message: "Kullanici yayınlanacak, onaylıyor musunuz?",
            acceptFunction: async () => await create(`yeniKullanici`, _data)
        });
    };
    const updateSuccessFunction = async () => {
        let _data = {
            ...formikUpdate.values,
            dogumTarihi: toISOStringWithTimezone(new Date(formikUpdate.values.dogumTarihi))
        };
        context.confirmDialog({
            message: "Kullanıcı güncellencek, onaylıyor musunuz?",
            acceptFunction: async () => await update(`kullaniciGuncelle`, _data)
        });;
    };
    const formik = KullaniciValidation(postSuccessFunction);
    const formikUpdate = KullaniciValidation(updateSuccessFunction);
    // DataTable Templates
    const islemTemplate = (data) => {
        return (
            <div className="flex gap-2">
                <Button className="p-button-sm p-button-warning" icon="pi pi-pencil" onClick={() => {
                    Object.entries(data)?.map(([key, value]) => {
                        if(key === "dogumTarihi"){
                            formikUpdate.setFieldValue(key, new Date(value));
                        }
                        else{
                            formikUpdate.setFieldValue(key, value);
                        }
                    });
                    setSelectedItem(data);
                    updateDialogToggle();
                }} />
                <Button className="p-button-sm p-button-danger" icon="pi pi-trash" onClick={() => context.confirmDialog({
                    message: "Seçili duyuru silinecek, onaylıyor musunuz?",
                    acceptFunction: async () => await remove(`kullaniciSil?id=${data?.id}`)
                })} />
            </div>
        );
    };
    // Api Action Result
    useEffect(() => {
        if (genericResult?.statusCode === 200) {
            getKullaniciListesi(`getAllKullanici?id=${loginData?.id}`);
            addDialog && addDialogToggle();
            updateDialog && updateDialogToggle();
            formik.resetForm();
            formikUpdate.resetForm();
        }
    }, [genericResult?.statusCode]);
    return (
        <>
            <ShadowedCard keys="Yaklaşan Doğum Günleri" title={loginData?.role === "E" ? <div className="flex justify-content-end" ref={ref}>
                {/* <span className='text-left select-none flex' style={{ fontSize: "1.5rem" }}><span className="flex flex-row align-items-center"><span className=" text-overflow-ellipsis overflow-hidden white-space-nowrap lg:max-w-7rem xl:max-w-full max-w-7rem">Yaklaşan Doğum Günleri</span></span></span> */}
                {collapsedBoxes["Yaklaşan Doğum Günleri"] ? <div className="flex w-auto">
                    <Button label="Yeni" className="p-button-text text-color p-button-sm" icon="pi pi-plus" iconPos="right" onClick={() => addDialogToggle()} />
                    <Button label="Listele" className="p-button-text text-color p-button-sm" icon="pi pi-list" iconPos="right" onClick={async () => {
                        await getKullaniciListesi(`getAllKullanici?id=${loginData?.id}`);
                        listDialogToggle();
                    }} />
                </div>:null}
            </div> : null //<span className='text-left select-none flex' style={{ fontSize: "1.5rem" }}><span className="flex flex-row align-items-center"><span className=" text-overflow-ellipsis overflow-hidden white-space-nowrap lg:max-w-7rem xl:max-w-full max-w-7rem">Yaklaşan Doğum Günleri</span></span></span>
        }>
                {loading ? (<div className='flex h-full justify-content-center align-items-center' style={{ minHeight: '300px' }}><ProgressSpinner /></div>) : (
                    <DataTable stripedRows size="small" header={null} scrollable style={{ height: "300px" }} scrollHeight="300px" tableClassName='datatable-test' value={dogumGunleri?.length > 0 ? dogumGunleri?.map(dg => ({
                    adi: `${dg?.adi} ${dg?.soyadi}`,
                    tarih: dg?.dogumTarihi
                })) : []} responsiveLayout="scroll">
                    <Column field="adi" headerClassName='hidden' />
                    <Column field="tarih" headerClassName='hidden' body={data => <div className='w-full'><span className='flex justify-content-end'>{data.tarih}</span></div>} />
                </DataTable>
                )}
            </ShadowedCard>
            <Dialog visible={listDialog} toggle={listDialogToggle} header="Kullanıcı Listesi">
                <DataTable value={dataHelper(kullaniciListesi?.data)} rows={10} paginator size="small" loading={kullaniciListesi?.loading} lazy={kullaniciListesi?.loading} stateStorage="session" dataKey="id" responsiveLayout="scroll" showGridlines stripedRows columnResizeMode="fit">
                    <Column className="col-3" header="E-posta" field="ePosta" />
                    <Column className="col-2" header="Adı" field="adi" />
                    <Column className="col-2" header="Soyadı" field="soyadi" />
                    <Column className="col-2" header="Doğum Tarihi" field="dogumTarihi" body={body => dateToStringConverter(body.dogumTarihi)} />
                    <Column className="col-2" header="Admin" field="admin" body={body => body.admin === "E" ? "Evet" : "Hayır"} />
                    <Column className="col-1" header="İşlem" body={islemTemplate} />
                </DataTable>
            </Dialog>
            <Dialog visible={addDialog} toggle={addDialogToggle} header="Kullanıcı Ekle" contentClassName="py-5">
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="field">
                        <span className="p-float-label">
                            <InputText id="ePosta" name="ePosta" placeholder="E-posta" className="p-inputtext-sm" onChange={formik.handleChange} value={formik.values.ePosta} />
                            <label htmlFor="ePosta" className={classNames({ 'p-error': isFormFieldValid(formik, "ePosta") })}>E-posta</label>
                        </span>
                    </div>
                    <div className="field pt-2">
                        <span className="p-float-label">
                            <InputText id="adi" name="adi" placeholder="Adı*" className="p-inputtext-sm" onChange={formik.handleChange} value={formik.values.adi} />
                            <label htmlFor="adi" className={classNames({ 'p-error': isFormFieldValid(formik, "adi") })}>Adı*</label>
                        </span>
                    </div>
                    <div className="field pt-2">
                        <span className="p-float-label">
                            <InputText id="soyadi" name="soyadi" placeholder="Soyadı*" className="p-inputtext-sm" onChange={formik.handleChange} value={formik.values.soyadi} />
                            <label htmlFor="soyadi" className={classNames({ 'p-error': isFormFieldValid(formik, "soyadi") })}>Soyadi*</label>
                        </span>
                    </div>
                    <div className="field pt-2">
                        <span className="p-float-label">
                            <Calendar id="dogumTarihi" name="dogumTarihi" placeholder="Doğum Tarihi*" className="p-inputtext-sm" onChange={formik.handleChange} value={formik.values.dogumTarihi} />
                            <label htmlFor="dogumTarihi" className={classNames({ 'p-error': isFormFieldValid(formik, "dogumTarihi") })}>Doğum Tarihi*</label>
                        </span>
                    </div>
                    <div className="field-checkbox">
                        <label htmlFor="admin" className={`${classNames({ 'p-error': isFormFieldValid(formik, "admin") })}`}>Admin:</label>
                            <Checkbox id="admin" name="admin" className="p-inputtext-sm ml-2" onChange={(e) => {
                                if(e.checked)
                                    formik.setFieldValue("admin", "E");
                                else
                                    formik.setFieldValue("admin", "H");
                            }} value={formik.values.admin} checked={formik.values?.admin === "E"} />
                    </div>
                    <div className="flex gap-2 justify-content-center">
                        <Button className="p-button-success w-auto" label="Tanımla" icon="pi pi-plus" iconPos="right" type="submit" />
                        <Button className="p-button-danger w-auto" label="İptal" icon="pi pi-times" iconPos="right" type="reset" onClick={() => {
                            formik.resetForm();
                            addDialog && addDialogToggle();
                        }} />
                    </div>
                </form>
            </Dialog>
            <Dialog visible={updateDialog} toggle={updateDialogToggle} onHide={() => setSelectedItem(null)} header="Kullanıcı Güncelle" contentClassName="py-5">
                <form onSubmit={formikUpdate.handleSubmit} className="p-fluid">
                <div className="field">
                        <span className="p-float-label">
                            <InputText id="ePosta" name="ePosta" placeholder="E-posta" className="p-inputtext-sm" onChange={formikUpdate.handleChange} value={formikUpdate.values.ePosta} />
                            <label htmlFor="ePosta" className={classNames({ 'p-error': isFormFieldValid(formikUpdate, "ePosta") })}>E-posta</label>
                        </span>
                    </div>
                    <div className="field pt-2">
                        <span className="p-float-label">
                            <InputText id="adi" name="adi" placeholder="Adı*" className="p-inputtext-sm" onChange={formikUpdate.handleChange} value={formikUpdate.values.adi} />
                            <label htmlFor="adi" className={classNames({ 'p-error': isFormFieldValid(formikUpdate, "adi") })}>Adı*</label>
                        </span>
                    </div>
                    <div className="field pt-2">
                        <span className="p-float-label">
                            <InputText id="soyadi" name="soyadi" placeholder="Soyadı*" className="p-inputtext-sm" onChange={formikUpdate.handleChange} value={formikUpdate.values.soyadi} />
                            <label htmlFor="soyadi" className={classNames({ 'p-error': isFormFieldValid(formikUpdate, "soyadi") })}>Soyadi*</label>
                        </span>
                    </div>
                    <div className="field pt-2">
                        <span className="p-float-label">
                            <Calendar id="dogumTarihi" name="dogumTarihi" placeholder="Doğum Tarihi*" className="p-inputtext-sm" onChange={formikUpdate.handleChange} value={formikUpdate.values.dogumTarihi} />
                            <label htmlFor="dogumTarihi" className={classNames({ 'p-error': isFormFieldValid(formikUpdate, "dogumTarihi") })}>Doğum Tarihi*</label>
                        </span>
                    </div>
                    <div className="field-checkbox">
                        <label htmlFor="admin" className={`${classNames({ 'p-error': isFormFieldValid(formikUpdate, "admin") })}`}>Admin:</label>
                            <Checkbox id="admin" name="admin" className="p-inputtext-sm ml-2" onChange={(e) => {
                                if(e.checked)
                                    formikUpdate.setFieldValue("admin", "E");
                                else
                                    formikUpdate.setFieldValue("admin", "H");
                            }} value={formikUpdate.values.admin} checked={formikUpdate.values?.admin === "E"} />
                    </div>
                    <div className="flex gap-2 justify-content-center">
                        <Button className="p-button-success w-auto" label="Güncelle" icon="pi pi-refresh" iconPos="right" type="submit" />
                        <Button className="p-button-danger w-auto" label="Temizle" icon="pi pi-trash" iconPos="right" type="button" onClick={() => {
                            Object.entries(selectedItem)?.map(([key, value]) => {
                                if(key === "dogumTarihi"){
                                    formikUpdate.setFieldValue(key, new Date(value));
                                }
                                else{
                                    formikUpdate.setFieldValue(key, value);
                                }
                            });
                        }} />
                    </div>
                </form>
            </Dialog>
        </>
    );
};
export default DogumGunleri;