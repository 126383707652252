// React
import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// PrimeReact
import PrimeReact, { locale } from 'primereact/api';
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "components/ui/ConfirmDialog";
// Context
import { Context } from "utils/Context";
// Hooks
import { useStore } from "store";
import { useToggle } from "hooks/useToggle";
import { useWindowDimension } from "hooks/useWindowDimension";
// Helpers
import { localizations } from "utils/helpers";
// Config
import * as Config from "Config";

export const SuBilgi = ({ children }) => {
    // Genel ayarlar
    localizations();
    PrimeReact.ripple = true;
    // Değişkenler
    const toast = useRef(null);
    const [activeMenuIndex, setActiveMenuIndex] = useState(-1);
    const [activeSubMenuIndex, setActiveSubMenuIndex] = useState(-1);
    const _dimension = useWindowDimension();
    const dimension = {
        width: _dimension.width,
        height: _dimension.height
    };
    // Confirm Dialog
    const { open, toggle: confirmToggle } = useToggle();
    const [message, setMessage] = useState("");
    const [acceptFunction, setAcceptFunction] = useState(null);
    const [rejectFunction, setRejectFunction] = useState(null);
    const confirmDialog = ({ message, acceptFunction, rejectFunction = null }) => {
        setMessage(message);
        // eslint-disable-next-line no-unused-expressions
        setAcceptFunction(() => () => {
            acceptFunction && acceptFunction();
            open && confirmToggle();
        }),
            setRejectFunction(() => () => {
                rejectFunction && rejectFunction();
                open && confirmToggle();
            });
        !open && confirmToggle();
    };
    /* Toast */
    // Toast Fonksiyonları
    const newMessage = (severity, summary, detail, life = null) => {
        toast.current.show({ severity: severity, summary: summary, detail: detail, life: life ? life : Config.TOAST_MESSAGE_DURATION, closable: !detail?.includes("Hata numarası:") ? true : false });
    };
    const newMessages = (values) => {
        toast.current.show(values?.map(v => ({ severity: "error", summary: "Hata!", detail: v, life: Config.TOAST_MESSAGE_DURATION, closable: !v?.includes("Hata numarası:") ? true : false })));
    };
    const clearMessages = () => {
        toast.current.clear();
    };
    // Sayfa değiştikçe toast mesajlarını kaldırır
    let location = useLocation();
    useEffect(() => {
        clearMessages();
    }, [location, window.location.pathname]);
    useEffect(() => {
        locale('tr');
    }, []);
    // Data
    var result = {
        activeMenuIndex, setActiveMenuIndex,
        activeSubMenuIndex, setActiveSubMenuIndex,
        toast, newMessage, newMessages, clearMessages,
        confirmDialog,
        ...dimension,
        ...Config
    };
    // Render
    return (
        <Context.Provider value={result}>
            <Toast ref={toast} position="bottom-left" />
            {children}
            <ConfirmDialog
                visible={open}
                toggle={confirmToggle}
                message={`${message}`}
                header={Config.ConfirmDialogProperties.header}
                icon={Config.ConfirmDialogProperties.icon}
                accept={() => {
                    acceptFunction && acceptFunction();
                    setMessage("");
                    setAcceptFunction(null);
                    setRejectFunction(null);
                    open && confirmToggle();
                }}
                reject={() => {
                    rejectFunction && rejectFunction();
                    setMessage("");
                    setAcceptFunction(null);
                    setRejectFunction(null);
                    open && confirmToggle();
                }}
                onHide={() => {
                    setMessage("");
                    setAcceptFunction(null);
                    setRejectFunction(null);
                    open && confirmToggle();
                }} />
        </Context.Provider>
    );
};