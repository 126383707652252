import { useState, useEffect } from "react";

export const useWindowDimension = () => {
   const [dimension, setDimension] = useState([
      window.innerWidth,
      window.innerHeight,
   ]);
   useEffect(() => {
      const debouncedResizeHandler = debounce(() => {
         setDimension([window.innerWidth, window.innerHeight]);
      }, 100); // 100ms
      window.addEventListener('resize', debouncedResizeHandler);
      return () => window.removeEventListener('resize', debouncedResizeHandler);
   }, []);
   return { width: dimension[0], height: dimension[1] };
}

function debounce(fn, ms) {
   let timer;
   return _ => {
      clearTimeout(timer);
      timer = setTimeout(_ => {
         timer = null;
         fn.apply(this, arguments);
      }, ms);
   };
}
