import React from 'react'
// PrimeReact
import { Dialog as Dlg } from 'primereact/dialog';

export const Dialog = ({ visible, toggle, onHide = null, children, ...props }) => {
    return <Dlg visible={visible} style={{ minWidth: '60vw' }} position="center" draggable={false} resizable={false} closeOnEscape={true} dismissableMask={true}
        onHide={() => {
            onHide && onHide();
            visible && toggle();
        }}  {...props}>
        {children}
    </Dlg>;
};