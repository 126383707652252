import axios from "axios";
// Config
import { BASE_URL } from "Config";
// Cookies
import { getCookie } from "utils/functions/cookies";
// Functions
import { logoutFn } from "utils/functions/auth";

export default function api(token = null) {
    if(token === null)
        token = String(getCookie("tokens"));
        
    let instance = axios.create({
        baseURL: BASE_URL,
        timeout: 300000,
        headers: {
            Authorization: `Bearer${(token !== "") ? ` ${token}` : ''}`,
        },
    });
    instance.interceptors.response.use(async (response) => {
         return response;
    }, async (error) => {
        if (error.response.status === 401) {
            logoutFn({ refresh: false });
            return Promise.reject(error);
        }
        return Promise.reject(error);
    });
    return instance;
};