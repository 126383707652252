// Cookies
import { deleteAllCookies } from "./cookies";
// Utils
import isExistInLocalStorage from "./isExistInLocalStorage";
// Config
import { DashboardOrder } from "Config";

export const logoutFn = () => {
    deleteAllCookies();
    sessionStorage.clear();
    localStorage.setItem("loggedIn", "false");
};

export const loginHelpingFn = ({ value, state }) => {
    localStorage.setItem("loggedIn", "true");

    if (!isExistInLocalStorage(value?.ePosta)) {
        localStorage.setItem(value?.ePosta, JSON.stringify({
            dashboardOrder: JSON.stringify(DashboardOrder)
        }));
    }
    else{
        const _local = JSON.parse(localStorage.getItem(value.ePosta));
        if(Object.values(JSON.parse(_local.dashboardOrder))?.length !== Object.values(DashboardOrder)?.length){
            localStorage.setItem(value?.ePosta, JSON.stringify({
                dashboardOrder: JSON.stringify(DashboardOrder)
            }));
        }
        else {
            state.dashboardOrder = JSON.parse(_local.dashboardOrder);
        }
    }
}