import React from 'react'
// PrimeReact
import { Divider } from 'primereact/divider';
// Components
import { ShadowedCard } from 'components/common/ShadowedCard';
// Context
import { Context } from 'utils/Context';
// Hooks
import { useStore } from 'store';
// Services
import api from 'services/api';
// Helpers
import { apiHelper } from 'utils/helpers';

const InteractiveUygulamalar = ({ loginData, IZIN_URL }) => {
    // Store
    const { doLogout } = useStore();
    // Variables
    const context = React.useContext(Context);
    return (
        <ShadowedCard keys="İnteraktif Uygulamalar" 
        // title={<span className='flex justify-content-start text-elipsis-portal' style={{ fontSize: "1.5rem", display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100%" }}>İnteraktif Uygulamalar</span>}
        >
            <div style={{height: "185px"}}>
                <Divider/>
                <div className='px-3 text-sm flex flex-row gap-2 w-full text-base align-items-center'>
                    <i className='pi pi-user mr-2' style={{color: 'dodgerblue'}}/>
                    <a href="#" target="_self"
                       onClick={async () => await apiHelper(async () => await api().post("izinLogin", {
                           ePosta: loginData?.ePosta,
                           portalToken: loginData?.token
                       })).then(res => {
                           if (res?.statusCode === 200) {
                               setTimeout(() => {
                                   window.open(`${IZIN_URL}portalLogin/${res?.result}`, 'izin')
                               })
                           } else if (res?.statusCode !== 0) {
                               context.newMessage("error", "Hata", "Oturumunuz sonlandı, tekrar giriş yapınız...");
                               setTimeout(() => doLogout(), 1500);
                           }
                       })} className='text-black-alpha-90 no-underline hover:underline'>KoLeave - İzin Uygulaması</a>
                    {/* <a href="https://intranet.kolin.com.tr/" target="intranet" className='text-black-alpha-90 no-underline hover:underline'>Intranet - Personel Yönetim Sistemi</a> */}
                </div>
                <Divider/>
                <div className='px-3 text-sm flex flex-row gap-2 w-full text-base align-items-center'>
                    <i className='pi pi-envelope mr-2' style={{color: 'dodgerblue'}}/>
                    <a href='https://mail3.kolin.com.tr/' target="_blank"
                       className='text-black-alpha-90 no-underline hover:underline'>Kolin Email Web</a>
                </div>
                <Divider/>
                <div className='px-3 text-sm flex flex-row gap-2 w-full text-base align-items-center'>
                    <i className='pi pi-phone mr-2' style={{color: 'dodgerblue'}}/>
                    <a href={`/SantralDahiliListe.xlsx`} target="_blank"
                       className='text-black-alpha-90 no-underline hover:underline'>Merkez Telefon Rehberi</a>
                </div>
                <Divider/>
                <div className='px-3 text-sm flex flex-row gap-2 w-full text-base align-items-center'>
                    <i className='pi pi-list mr-2' style={{color: 'dodgerblue'}}/>
                    <a href={`/YemekListe.xlsx`} target="_blank"
                       className='text-black-alpha-90 no-underline hover:underline'>Yemek Listesi</a>
                </div>
                <Divider/>
            </div>
        </ShadowedCard>
    );
};
export default InteractiveUygulamalar;